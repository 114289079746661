import './assets/sass/index.scss';
import * as jose from 'jose';
var smlVersion="v1.0.7";
console.log("Slotmaster Launcher "+smlVersion);

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

async function generateToken(val){
  var secret = new TextEncoder().encode('hungrybearhungrybearhungrybear23')
  var alg = 'HS256'
  var token = await new jose.SignJWT({ 'email': val, 'company' : 'ICE' })
      .setProtectedHeader({ alg })
      .setExpirationTime('2h')
      .sign(secret)

      
  
  var url = "https://icedemo.slotmastersbattle.com?jwt=" + token;
  document.getElementById('slotmasters-iframe').src = url;  
}
function deleteCookie(){
  setCookie("smlUser","",10);
}

function validateEmail(email){
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

function init(){

  var cookie = getCookie("smlUser");
  var smlPopup = document.getElementById('smlPopupContainer');
  if(cookie === ""){
    
    smlPopup.style.display = "block";
    var smlSubmit = document.getElementById("smlSubmit");
    smlSubmit.addEventListener("click", (e) => {
      var smlError = document.getElementById("smlError");
      if(smlError){
        smlError.innerText = "";
      }
      var emailInput = document.getElementById("smlEmail");
      var smlOver18 = document.getElementById("smlOver18");

      if(smlOver18){
        if(!smlOver18.checked){
          smlError.innerText = "Please select over 18";
          return;
        }
      }
      if(emailInput){
        var emailtxt = emailInput.value;
        if(emailtxt !== ""){
          if(validateEmail(emailtxt)){
            setCookie("smlUser", emailtxt, 365);
            //smlPopup.style.display = "none";
            smlPopup.remove();
            generateToken(emailtxt)
          }else{
            smlError.innerText = "Please enter a valid email address"
          }
  
        }else{
          smlError.innerText = "Please enter an email address"
        }
      }
    })

  }else{
    if(smlPopup){
      smlPopup.remove();
    }
    generateToken(cookie);
  }
}
function resize(){
    var w = window.innerWidth;
    var h = window.innerHeight;

    document.getElementById('slotmasters-iframe').style.height = h+"px";
    document.getElementById('slotmasters-iframe').style.width = w+"px";

}


document.addEventListener(
  "DOMContentLoaded",
  () => {
      document.addEventListener('touchmove', e => {
        if (e.touches.length > 1) {  
          e.preventDefault();
        }
      }, {passive: false})
      init();
      addEventListener("resize", (event) => {
        resize();
      });

      resize();
  },
  { once: true }
);

